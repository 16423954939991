<template>
  <svg
    :width="size"
    :height="size"
    :class="direction"
    class="transition duration-100 cursor-pointer"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    @click="$emit('click')"
  >
    <path
      d="M5.25 7.5L9 11.25L12.75 7.5H5.25Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
export default {
  name: 'DropdownArrow',
  props: {
    direction: {
      type: String,
      default: 'left',
      validator: val => ['left', 'right', 'down', 'up'].includes(val),
    },
    size: {
      type: Number,
      default: 18,
    },
  },
}
</script>
<style lang="scss" scoped>
.up {
  transform: rotate(180deg);
}

.right {
  transform: rotate(-90deg);
}

.left {
  transform: rotate(90deg);
}
</style>
